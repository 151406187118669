import React from 'react';
import Layout from '../Layout';
import Title from '../Title';
import OpenAccount from '../OpenAccount';


export default function Shares() {
    return (
        <Layout>
            <Title name={'Shares'} />

            <div className='py-20'>
                <div className='container mx-auto text-center px-3 lg:px-0'>
                    <h2 className='heading mb-5 max-w-4xl mx-auto'>Trading Shares with <span className='text-[#03880e]'>Trade24 What Are Shares</span></h2>
                    <p className='text-sm sm:text-base font-light'>A share or stock is a document issued by a company, which entitles its holder to be one of the owners of the company. A share is issued by a company or can be purchased from the stock market. By purchasing a share you can earn a portion of the company and by selling the shares you get capital gain.</p>
                    <p className='text-sm sm:text-base font-light mt-2'>Share CFDs, or Share CFD trading, creates a unique opportunity to speculate on the share price movements of publicly listed companies traded on exchanges such as DAX, FTSE, NYSE and Nasdaq, without the need to own the underlying stock. You can even take advantage of market conditions when shares are trending in bearish territory.</p>
                </div>
            </div>

            <div className='py-14 sm:py-20'>
                <div className='container mx-auto text-center px-3 lg:px-0'>
                    <div className='grid grid-cols-2 sm:grid-cols-3 gap-5'>
                        <div>
                            <img src="images/trade-over-icon1.webp" alt="Trade" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Trade Indian & US Stocks</p>
                        </div>
                        <div>
                            <img src="images/trade-over-icon2.webp" alt="Competitive" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Competitive Spreads</p>
                        </div>
                        <div>
                            <img src="images/trade-over-icon3.webp" alt="Margin" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>500x Margin</p>
                        </div>
                    </div>
                </div>
            </div>            

            <OpenAccount />
        </Layout>
    )
}