import React from 'react';
import Layout from '../Layout';
import Title from '../Title';
import OpenAccount from '../OpenAccount';


export default function Forex() {
    return (
        <Layout>
            <Title name={'Forex'} />

            <div className='py-20'>
                <div className='container mx-auto text-center px-3 lg:px-0'>
                    <h2 className='heading'>Trading Forex with <span className='text-[#03880e]'>Trade24</span></h2>
                    <h2 className='heading mb-5 text-[#03880e]'>What Is Forex?</h2>
                    <p className='text-sm sm:text-base font-light'>Forex, also known as foreign exchange, is a global marketplace for exchanging currencies against one another. Forex markets reach large volumes and are also the most liquid in the world. With Trade24 forex traders you can trade dozens of FX pairs, among which you can find the Majors, popular Minors and Exotics.</p>
                    <div className='mt-10 grid grid-cols-2 sm:grid-cols-3 gap-5 sm:gap-7'>
                        <div>
                            <img src="images/trade-over-icon1.webp" alt="trade-over" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Trade over 30+ currencies pairs</p>
                        </div>
                        <div>
                            <img src="images/trade-over-icon2.webp" alt="trade-over" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Trade over 30+ currencies pairs</p>
                        </div>
                        <div>
                            <img src="images/trade-over-icon3.webp" alt="trade-over" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Trade over 30+ currencies pairs</p>
                        </div>
                        <div>
                            <img src="images/trade-over-icon5.webp" alt="trade-over" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Trade over 30+ currencies pairs</p>
                        </div>
                        <div>
                            <img src="images/trade-over-icon6.webp" alt="trade-over" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Trade over 30+ currencies pairs</p>
                        </div>
                        <div>
                            <img src="images/trade-over-icon7.webp" alt="trade-over" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Trade over 30+ currencies pairs</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-forex py-20 sm:py-28'>
                <div className='max-w-[800px] mx-auto text-center px-3 lg:px-0'>
                    <h2 className='heading text-white'>Trade24 - The Best Forex Trading Platform</h2>
                    <p className='text-sm sm:text-base text-white font-medium mt-5'>Forex is the exchange for trading in foreign currencies - it is one of the most favoured instruments for trade currently available. When it comes to software for trading online, there are several platforms available - however, the experience of the trader can only be optimized by the best forex trading platform you can find.</p>
                </div>
            </div>

            <OpenAccount />
        </Layout>
    )
}