import { faInstagram, faTelegram} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function StartTrading() {
    return (
        <div className='bg-[#f8f9fe] relative py-20'>
            <div className='container mx-auto px-3 lg:px-0 flex justify-between flex-col sm:flex-row'>
                <div className="max-w-xl">
                    <h2 className="text-[#03880e] text-3xl font-light">Start trading with Trade24 in 3 easy <span className="text-[#4a4a4a]">steps!</span></h2>
                    <Link target='_' to={"https://api.whatsapp.com/send?phone=919090782424"}>
                        <button className="solidBtn mt-6">Start Trading Now</button>
                    </Link>
                </div>
                <div className="mt-5 sm:mt-0 sm:w-5/12">
                    <h2 className="text-2xl font-semibold">We are lovely social</h2>
                    <ul className="flex mt-5">
                        <li className="border-[#03880e] transition-all duration-500 border-2 w-9 h-9 mr-3 cursor-pointer rounded-full flex items-center justify-center group hover:bg-[#03880e]">
                            <Link target='_' to={"https://www.instagram.com/trade24live?igsh=bW5hbXR4NDRvbjk0"} className="p-2">
                                <FontAwesomeIcon icon={faInstagram} className="text-[#03880e] group-hover:text-white transition-all duration-500" />
                            </Link>
                        </li>
                        <li className="border-[#03880e] transition-all duration-500 border-2 w-9 h-9 mr-3 cursor-pointer rounded-full flex items-center justify-center group hover:bg-[#03880e]">
                            <Link target='_' to={"https://t.me/+vT_P1C21nck0MDll"} className="p-2">
                                <FontAwesomeIcon icon={faTelegram} className="text-[#03880e] group-hover:text-white transition-all duration-500" />
                            </Link>
                        </li>
                        {/* <li className="border-[#03880e] transition-all duration-500 border-2 w-9 h-9 mr-3 cursor-pointer rounded-full flex items-center justify-center group hover:bg-[#03880e]"><FontAwesomeIcon icon={faLinkedinIn} className="text-[#03880e] group-hover:text-white transition-all duration-500" /></li>
                        <li className="border-[#03880e] transition-all duration-500 border-2 w-9 h-9 mr-3 cursor-pointer rounded-full flex items-center justify-center group hover:bg-[#03880e]"><FontAwesomeIcon icon={faXTwitter} className="text-[#03880e] group-hover:text-white transition-all duration-500" /></li>
                        <li className="border-[#03880e] transition-all duration-500 border-2 w-9 h-9 cursor-pointer rounded-full flex items-center justify-center group hover:bg-[#03880e]"><FontAwesomeIcon icon={faYoutube} className="text-[#03880e] group-hover:text-white transition-all duration-500" /></li> */}
                    </ul>
                </div>
            </div>
        </div>
    )
}