import { faInstagram, faTelegram} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Footer() {
    const navigate = useNavigate()

    useEffect(()=> {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <div className='py-16 px-0 sm:px-8 bg-[#013208]'>
                <div className="container px-3">
                    <div className="flex flex-wrap">
                        <div className="w-full sm:w-1/2 lg:w-[26%] px-3">
                            <h4 className="text-lg sm:text-2xl mb-5 sm:mb-7 text-white font-medium">Connect with Us</h4>
                            <ul>
                                <li className="font-light mb-4 text-[#c9ffc6] flex ">
                                    <FontAwesomeIcon icon={faLocationDot} className="mt-1" />
                                    <p className="ml-3">21/23, Al Hamriya Street, Dubai, UAE</p>                       
                                </li>
                                <li className="font-light cursor-pointer mb-4 text-[#c9ffc6] hover:text-white flex ">
                                    <FontAwesomeIcon icon={faPhone} className="mt-1" />
                                    <Link target='_' to={"https://api.whatsapp.com/send?phone=919090782424"}>
                                        <p className="ml-3">+91 90907 82424</p>                       
                                        <p className="ml-3">+91 90905 72424</p>  
                                    </Link>                     
                                </li>
                                <li className="font-light cursor-pointer mb-4 text-[#c9ffc6] hover:text-white flex ">
                                    <FontAwesomeIcon icon={faEnvelope} className="mt-1" />
                                    <Link target="_" to={'mailto:support@trade24live.com'}>
                                        <p className="ml-3">support@trade24live.com</p>       
                                    </Link>                
                                </li>
                            </ul>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-[16%] px-3 mt-5 sm:mt-0">
                            <h4 className="text-lg sm:text-2xl mb-5 sm:mb-7 text-white font-medium">About Us</h4>
                            <ul>
                                <li className="font-light cursor-pointer mb-3 text-sm sm:text-base text-[#c9ffc6] hover:text-white" onClick={()=>navigate('/about')}>Why Trade24?</li>
                                <li className="font-light cursor-pointer mb-3 text-sm sm:text-base text-[#c9ffc6] hover:text-white" onClick={()=>navigate('/regulation')}>Regulations</li>
                                <li className="font-light cursor-pointer mb-3 text-sm sm:text-base text-[#c9ffc6] hover:text-white" onClick={()=>navigate('/faq')}>FAQs</li>
                            </ul>
                        </div>
                        <div className="w-full sm:w-1/3 sm:mt-5 lg:w-[16%] px-3 mt-5 lg:mt-0">
                            <h4 className="text-lg sm:text-2xl mb-5 sm:mb-7 text-white font-medium">Product</h4>
                            <ul>
                                <li className="font-light cursor-pointer mb-3 text-sm sm:text-base text-[#c9ffc6] hover:text-white" onClick={()=>navigate('/forex')}>Forex</li>
                                <li className="font-light cursor-pointer mb-3 text-sm sm:text-base text-[#c9ffc6] hover:text-white" onClick={()=>navigate('/metals')}>Metals</li>
                                <li className="font-light cursor-pointer mb-3 text-sm sm:text-base text-[#c9ffc6] hover:text-white" onClick={()=>navigate('/energies')}>Energies</li>
                                <li className="font-light cursor-pointer mb-3 text-sm sm:text-base text-[#c9ffc6] hover:text-white" onClick={()=>navigate('/indices')}>Indices</li>
                                <li className="font-light cursor-pointer mb-3 text-sm sm:text-base text-[#c9ffc6] hover:text-white" onClick={()=>navigate('/shares')}>Shares</li>
                            </ul>
                        </div>
                        <div className="w-full sm:w-1/3 sm:mt-5 lg:w-[26%] px-3 mt-5 lg:mt-0">
                            <h4 className="text-lg sm:text-2xl mb-5 sm:mb-7 text-white font-medium">Traders</h4>
                            <ul>
                                <li className="font-light cursor-pointer mb-3 text-sm sm:text-base text-[#c9ffc6] hover:text-white" onClick={()=>navigate('/maintainance')}>Maintainance Margin</li>
                                <li className="font-light cursor-pointer mb-3 text-sm sm:text-base text-[#c9ffc6] hover:text-white" onClick={()=>navigate('/intraday')}>Intraday Margin</li>
                            </ul>
                        </div>
                        <div className="w-full sm:w-1/3 sm:mt-5 lg:w-[16%] px-3 mt-5 lg:mt-0">
                            <h4 className="text-lg sm:text-2xl mb-5 sm:mb-7 text-white font-medium">Platforms</h4>
                            <ul>
                                <li className="font-light cursor-pointer mb-3 text-sm sm:text-base text-[#c9ffc6] hover:text-white">
                                    <a href={'./apk/com.trade24live.in.apk'} download={'./apk/com.trade24live.in.apk'}>Android App</a>
                                </li>
                                <li className="font-light cursor-pointer mb-3 text-sm sm:text-base text-[#c9ffc6] hover:text-white">
                                    <a target="_" href='https://www.trade24live.in'>iOS App</a>
                                </li>
                                <li className="font-light cursor-pointer mb-3 text-sm sm:text-base text-[#c9ffc6] hover:text-white">
                                    <a target="_" href='https://www.trade24live.in'>Desktop Windows</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex items-center justify-center mt-7">
                        <h4 className="text-white font-medium text-xl mr-4">Follow us :</h4>
                        <div className="flex">
                            <div className="w-6 h-6 mx-2 bg-white rounded-full flex items-center justify-center cursor-pointer">
                                <Link target='_' to={"https://www.instagram.com/trade24live?igsh=bW5hbXR4NDRvbjk0"} className="p-1">
                                    <FontAwesomeIcon icon={faInstagram} className="text-xs" />
                                </Link>
                            </div>
                            <div className="w-6 h-6 mx-2 bg-white rounded-full flex items-center justify-center cursor-pointer">
                                <Link target='_' to={"https://t.me/+vT_P1C21nck0MDll"} className="p-1">
                                    <FontAwesomeIcon icon={faTelegram} className="text-xs" />
                                </Link>
                            </div>
                            {/* <div className="w-6 h-6 mx-2 bg-white rounded-full flex items-center justify-center cursor-pointer"><FontAwesomeIcon icon={faFacebookF} className="text-xs" /></div>
                            <div className="w-6 h-6 mx-2 bg-white rounded-full flex items-center justify-center cursor-pointer"><FontAwesomeIcon icon={faLinkedinIn} className="text-xs" /></div>
                            <div className="w-6 h-6 mx-2 bg-white rounded-full flex items-center justify-center cursor-pointer"><FontAwesomeIcon icon={faYoutube} className="text-xs" /></div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-8 px-3 sm:px-8 bg-[#0a6a12] text-center sm:text-left">
                <div className="container px-3 relative sm:flex flex-wrap">
                    <p className="font-light text-sm sm:text-base text-white">Copyright © 2024 Trade24. All Rights Reserved.</p>
                    <div className="ml-0 sm:ml-16 my-3 sm:my-0">
                        <span className="font-light text-sm sm:text-base text-white cursor-pointer">Privacy Policy</span>
                        <span className="font-light text-sm sm:text-base text-white mx-3">|</span>
                        <span className="font-light text-sm sm:text-base text-white cursor-pointer">Contact Us</span>
                    </div>
                    <a href={'./apk/com.trade24live.in.apk'} download={'./apk/com.trade24live.in.apk'} className="lg:absolute cursor-pointer max-w-[192px] sm:max-w-max flex items-center bg-white mx-auto rounded-2xl px-3 sm:pr-9 sm:pl-7 py-3 right-0 -top-[70px] sm:mt-5 lg:mt-0">
                            <img src="images/anroid-icon.png" alt="Anroid" className="w-9 sm:w-auto" />
                            <div className="ml-3">
                                <h5 className="text-[#392557] text-base sm:text-2xl font-semibold">Download app</h5>
                                <p className="text-[#4a4a4a] text-xs sm:text-base font-light">For android devices</p>
                            </div>
                    </a>
                </div>
            </div>
        </>
    )
}