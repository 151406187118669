import React from 'react';
import Layout from '../Layout';
import Title from '../Title';
import OpenAccount from '../OpenAccount';


export default function Regulation() {
    return (
        <Layout>
            <Title name={'Regulation'} />
            
            <div className='py-20 mx-3 sm:mx-0'>
                <div className='container mx-auto '>
                    <h2 className='heading text-center mb-10'>Trade24 <span className='text-[#03880e]'>Regulations & Licenses</span></h2>
                    <div className='flex gap-7 flex-col sm:flex-row'>
                        <div className='w-full sm:w-6/12 bg-white shadow-reg rounded-xl p-5'>
                            <h3 className='text-4xl font-semibold text-[#03880e]'>01.</h3>
                            <h4 className='text-xl mt-4 mb-3 font-semibold'>Vanuatu Financial Services Commission (VFSC)</h4>
                            <p className='text-base font-light text-[#4a4a4a]'>Trade24 Limited is registered by the Vanuatu Financial Services Commission (VFSC) 16819</p>
                        </div>
                        <div className='w-full sm:w-6/12 bg-white shadow-reg rounded-xl p-5'>
                            <h3 className='text-4xl font-semibold text-[#03880e]'>01.</h3>
                            <h4 className='text-xl mt-4 mb-3 font-semibold'>St. Vincent & the Grenadines</h4>
                            <p className='text-base font-light text-[#4a4a4a]'>Trade24 Limited is registered in St. Vincent & the Grenadines as an International Business Company with the registration number 26065 BC 2020.</p>
                        </div>
                    </div>
                </div>
            </div>

            <OpenAccount />

        </Layout>
    )
}