import React from 'react';
import Layout from '../Layout';
import Title from '../Title';
import OpenAccount from '../OpenAccount';


export default function Energies() {
    return (
        <Layout>
            <Title name={'Energies'} />

            <div className='py-20'>
                <div className='container mx-auto text-center px-3 lg:px-0'>
                    <h2 className='heading mb-5 max-w-4xl mx-auto'>Trading in Energy with <span className='text-[#03880e]'>Trade24 What Are Energies?</span></h2>
                    <p className='text-sm sm:text-base font-light'>The world moves on oil, quite literally actually. And with a commodity that enjoys as all-pervasive a demand as oil, trading offers opportunities for not just safe investments but also abundant returns. Energy trading remains a profitable prospect in nearly all market conditions, especially considering its unique position in the economic and political systems of the world. They don't call it 'Black Gold' for nothing you see.</p>
                    <p className='text-sm sm:text-base font-light'>So if you are looking to dip your feet into the ocean of opportunities that energy trading can offer, get yourself aligned with an energy trading platform like Trade24 - proven to be reliable trading partners on your journey towards growths.</p>
                </div>
            </div>

            <div className='py-14 sm:py-20 bg-[#f8f9fe]'>
                <div className='container mx-auto text-center px-3 lg:px-0'>
                    <div className='grid grid-cols-2 sm:grid-cols-4 gap-5'>
                        <div>
                            <img src="images/low-commissions1.webp" alt="low-commissions" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Zero Commissions</p>
                        </div>
                        <div>
                            <img src="images/hidden-charges.webp" alt="hidden-charges" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>No Overnight Charges</p>
                        </div>
                        <div>
                            <img src="images/requiremens1.png" alt="requiremens" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Low Margin Requirements</p>
                        </div>
                        <div>
                            <img src="images/zero-commission.png" alt="zero-commission" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Greater Price Movements</p>
                        </div>
                    </div>
                </div>
            </div>            

            <div className='container py-20 mx-auto px-3 lg:px-0 flex items-center flex-col sm:flex-row'>
                <div className='sm:w-7/12 sm:pr-16 order-2 sm:order-1'>
                    <h2 className='heading mb-5'>Introduction: <span className='text-[#03880e]'>Energy Trading</span></h2>
                    <p className='text-sm sm:text-base font-light mt-3'>Energies are often the asset class of Oil and its derivatives. Though with the evolving interest of the world into alternate power sources, asset classes like wind power, emissions, gas, nuclear power and even electricity are capturing the imagination of investors. Crude oil however, still remains one of the most actively traded commodities in the world.</p>
                </div>
                <div className='sm:w-5/12 mb-8 sm:mb-0 order-1'>
                    <img src='images/metals-icon2.webp' alt='Metals' />
                </div>
            </div>

            <div className='py-20 bg-[#f8f9fe]'>
                <div className='container mx-auto text-center px-3 lg:px-0'>
                    <h2 className='heading mb-5'>Energy Trading - <span className='text-[#03880e]'>How Can You Start?</span></h2>
                    <p className='text-sm sm:text-base font-light'>Trading in energy is carried out through the buying and selling of oil and oil-linked derivatives. You can use CFDs on oil's spot price, or the prices of oil future contracts or options contracts. Here is how traders can begin their journey into the energy markets:</p>
                </div>
            </div>     

            <div className='py-20'>
                <div className='container mx-auto text-center px-3 lg:px-0'>
                    <h2 className='heading mb-5 max-w-4xl mx-auto'>Energy Trading <span className='text-[#03880e]'>What Moves the Market?</span></h2>
                    <p className='text-sm sm:text-base font-light'>When you look at the movement of prices on energy trading platforms , any trends can be attributed to supply and demand of oil in the market. And those factors are influenced by:</p>
                    <div className='mt-7 text-left grid grid-cols-1 sm:grid-cols-2 gap-8'>
                        <div className='text-[#4a4a4a] border border-[#03880e] rounded-md p-5'>
                            <h4 className='text-xl font-semibold mb-3'>OPEC Countries</h4>
                            <p className='text-sm sm:text-base font-light'>Are fully licensed and regulated - compliant with multiple market regulation authorities across the globe like the World Gold Council, National commodities markets and more.</p>
                        </div>
                        <div className='text-[#4a4a4a] border border-[#03880e] rounded-md p-5'>
                            <h4 className='text-xl font-semibold mb-3'>Global Economy</h4>
                            <p className='text-sm sm:text-base font-light'>Are fully licensed and regulated - compliant with multiple market regulation authorities across the globe like the World Gold Council, National commodities markets and more.</p>
                        </div>
                        <div className='text-[#4a4a4a] border border-[#03880e] rounded-md p-5'>
                            <h4 className='text-xl font-semibold mb-3'>Product Management Cost</h4>
                            <p className='text-sm sm:text-base font-light'>Are fully licensed and regulated - compliant with multiple market regulation authorities across the globe like the World Gold Council, National commodities markets and more.</p>
                        </div>
                        <div className='text-[#4a4a4a] border border-[#03880e] rounded-md p-5'>
                            <h4 className='text-xl font-semibold mb-3'>Alternative Fuels And Power Sources</h4>
                            <p className='text-sm sm:text-base font-light'>Are fully licensed and regulated - compliant with multiple market regulation authorities across the globe like the World Gold Council, National commodities markets and more.</p>
                        </div>
                    </div>
                </div>
            </div>       

            <OpenAccount />
        </Layout>
    )
}