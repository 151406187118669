import { BrowserRouter, Routes, Route } from "react-router-dom";
import routes from './route';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            {routes.map((item, index) => {
              return <Route exact={item.exact} key={index} path={item.path} Component={item.component} />                          
              })
            }
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
