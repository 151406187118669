import React from 'react';
import Layout from '../Layout';
import Title from '../Title';
import OpenAccount from '../OpenAccount';


export default function Indices() {
    return (
        <Layout>
            <Title name={'Indices'} />

            <div className='py-20'>
                <div className='container mx-auto text-center px-3 lg:px-0'>
                    <h2 className='heading mb-5 max-w-4xl mx-auto'>Trading Indices with <span className='text-[#03880e]'>Trade24</span></h2>
                    <p className='text-sm sm:text-base font-light'>Getting access to all the perks of market movement and economic trends without having to deal with the high risk of losing money - that is what Indices Trading is all about. Investors, veteran traders and market analysts, all consider Index CFDs to be a good vehicle to manage the risks associated with stock trading. The sheer simplicity offered by index trading happens to be its biggest allure. The idea is to speculate on the movement of market indices rather than putting in all the effort into researching individual company stocks and their growth potential before investing. Add in the efficiency of a modern Indices Trading Platform combined with trade partners like Trade24 and you have the perfect trifecta that every investor dreams about!</p>
                    <p className='text-sm sm:text-base font-light mt-2'>The sheer simplicity offered by index trading happens to be its biggest allure. The idea is to speculate on the movement of market indices rather than putting in all the effort into researching individual company stocks and their growth potential before investing. Add in the efficiency of a modern Indices Trading Platform combined with trade partners like Trade24 and you have the perfect trifecta that every investor dreams about!</p>
                </div>
            </div>

            <div className='py-14 sm:py-20 bg-[#f8f9fe]'>
                <div className='container mx-auto text-center px-3 lg:px-0'>
                    <div className='grid grid-cols-2 sm:grid-cols-5 gap-5'>
                        <div>
                            <img src="images/diversification.png" alt="diversification" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Diversification of portfolio</p>
                        </div>
                        <div>
                            <img src="images/ability.png" alt="ability" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Ability to short sell</p>
                        </div>
                        <div>
                            <img src="images/global.webp" alt="global" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Access to global economies</p>
                        </div>
                        <div>
                            <img src="images/commissions.webp" alt="commissions" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Zero Commissions</p>
                        </div>
                        <div>
                            <img src="images/leverage.png" alt="leverage" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>500x Margin</p>
                        </div>
                    </div>
                </div>
            </div>            

            <div className='py-10 bg-[#f8f9fe]'>
                <div className='container mx-auto text-center px-3 lg:px-0'>
                    <h2 className='heading mb-5'>Introduction: <span className='text-[#03880e]'>Indices Trading</span></h2>
                    <p className='text-sm sm:text-base font-light'>In simple terms, when you engage in trading indices, you are trading an index or compilation of shares rather than buying or selling individual company stocks. Many consider it to be a more passive trading style. However, it does offer lower risk of losing money when trading CFDs along with an opportunity to hold a diversified portfolio of investments and relative freedom from price movements. You get exposure to the entire economy with a single trade and can benefit from collective averaging of stocks as well as explore more creative and actionable trade ideas. Indices Trading is a win-win in every way, especially for an investor who is just starting out in the world of CFD trading.</p>
                </div>
            </div>     

            <div className='py-10'>
                <div className='container mx-auto px-3 lg:px-0'>
                    <h2 className='heading mb-5 max-w-4xl mx-auto text-center'>Indices Trading - <span className='text-[#03880e]'>What Is An Index?</span></h2>
                    <p className='text-sm sm:text-base font-light text-center mb-6'>ndexes represent the state of a broad industry sector by tracking the collective performance of a large group of carefully selected shares from the same segment of the economy. An index is calculated as a weighted average of the price of its constituent stocks and is taken as an indicator of health of the particular economy or industry that it is tracking.</p>

                    <h4 className='text-xl font-semibold mb-1'>Indices Trading - What types of Indices are out there?</h4>
                    <p className='text-sm sm:text-base font-light mb-5'>Popular indices to start trading include the Dow Jones Industrial Average, the S&P 500, the NASDAQ 100, the FTSE 100, the DAX30, the Nikkei 225, the ASX200 and so on. However, when you are looking to understand their basics, these indexes can be categorized into the following broad sub-types:</p>

                    <h4 className='text-xl font-semibold mb-1'>Country Based</h4>
                    <p className='text-sm sm:text-base font-light mb-5'>These represent the stock market performance of specific countries. Like the DAX30 tracks the economy of Germany while the S&P500 broadly represents the US stock market.</p>

                    <h4 className='text-xl font-semibold mb-1'>Exchange Based</h4>
                    <p className='text-sm sm:text-base font-light mb-5'>These are used to track the stocks listed on a particular stock exchange like the NASDAQ100 represents the non-financial stocks that are listed on the NASDAQ</p>

                    <h4 className='text-xl font-semibold mb-1'>Regional Based</h4>
                    <p className='text-sm sm:text-base font-light mb-5'>These Indexes are used to track the performance of stocks listed in specific geographic zones like the Asia Pacific Index does for the listed stocks from developed countries within Asia.</p>

                    <h4 className='text-xl font-semibold mb-1'>Sector Based</h4>
                    <p className='text-sm sm:text-base font-light mb-5'>These track the performance of stocks from specific market sectors like healthcare, finance and more. Apart from the above, there are also market-cap weighted indices and price-weighted indices that can be used by investors to trade indices.</p>
                </div>
            </div>  

            <div className='py-10'>
                <div className='container mx-auto px-3 lg:px-0'>
                    <h2 className='heading mb-5 text-center'>Indices Trading - <span className='text-[#03880e]'>What Drives Prices Of An Indice?</span></h2>
                    <p className='text-sm sm:text-base font-light text-center'>Losing money rapidly while playing the financial markets is the worst nightmare for any investor, regardless of their risk management appetite. The only way to protect yourself and your money is thorough market analysis, extensive trading experience and developing a keen eye for spotting trading opportunities. While trading stock indices does reduce the risks involved owing, traders still need to understand and follow the many factors that may influence particular indice prices and marker movements. These are some of the many factors that you need to watch out for when trading in stock index CFDs:</p>

                    <ul className='mt-5'>
                        <li className='text-sm sm:text-base relative before:content-[""] before:absolute before:w-2 before:h-2 before:bg-[#03880e] before:rotate-45 before:left-0 before:top-[10px] pl-6 mb-4'><span className='text-lg'>Market</span> News always has a direct impact on stock market performance. Any data like GDP numbers, interest rate movements, major industry announcements and so on can impact share prices and their linked indices.</li>
                        <li className='text-sm sm:text-base relative before:content-[""] before:absolute before:w-2 before:h-2 before:bg-[#03880e] before:rotate-45 before:left-0 before:top-[10px] pl-6 mb-4'><span className='text-lg'>Geo-Political</span> Events impact industry future as well as investor sentiment. These tend to drive stock markets into erratic directions. Terrorist attacks, international conflicts, trade wars and social unrest are all factors that can drive stock markets up or down.</li>
                        <li className='text-sm sm:text-base relative before:content-[""] before:absolute before:w-2 before:h-2 before:bg-[#03880e] before:rotate-45 before:left-0 before:top-[10px] pl-6 mb-4'><span className='text-lg'>Movements</span> In The Forex Market also create huge impacts in specific indexes. The FTSE100, for example is highly sensitive to trends in foreign exchange markets and therefore, traders dealing in such indices need to be watchful of any major market trends in the sector.</li>
                        <li className='text-sm sm:text-base relative before:content-[""] before:absolute before:w-2 before:h-2 before:bg-[#03880e] before:rotate-45 before:left-0 before:top-[10px] pl-6 mb-4'><span className='text-lg'>And finally, Investor</span> Sentiment and its impact on stock markets is a loosely understood yet ever-present factor that drives share prices in unexpected directions. Any trading strategy therefore, must take the emotional component of market movements into account to prevent loss of money when trading CFDss</li>
                    </ul>
                </div>
            </div>          

            <OpenAccount />
        </Layout>
    )
}