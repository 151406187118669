import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

export default function Title(props) {
    const navigate = useNavigate()
    return (
        <div className='bg-title relative py-20 bg-center  bg-cover before:content-[""] before:w-full before:h-full before:bg-[#111111] before:bg-opacity-50 before:absolute before:top-0 before:left-0 before:z-10'>
            <div className='container mx-auto relative z-20 px-3'>
                <h1 className='text-white text-4xl mb-4 font-bold'>{props.name}</h1>
                <div><span className='text-white text-lg font-medium cursor-pointer' onClick={()=>navigate('/')}>Home</span> <span className='text-white font-light mx-3'>|</span> <span className='text-white text-lg font-medium'>{props.name}</span></div>
            </div>
        </div>
    )
}

Title.propTypes = {
    name: PropTypes.string
};