import { Link } from "react-router-dom";

export default function OpenAccount() {
    return (
        <div className='bg-[#f8f9fe] relative py-20'>
            <div className='container mx-auto px-3 lg:px-0 flex justify-between flex-col sm:flex-row items-center relative before:content-[""] before:absolute before:w-[1px] before:h-40 before:bg-[#ddd] before:m-auto before:left-0 before:right-0 before:hidden before:sm:block'>
                <div className="max-w-md text-center">
                    <h2 className="text-2xl font-semibold">Trade in extensive range of Instruments & Access the Global Market</h2>
                    <Link target='_' to={"https://api.whatsapp.com/send?phone=919090782424"}>
                        <button className="solidBtn mt-6">Open An Account</button>
                    </Link>
                </div>
                <div className="mt-10 sm:mt-0 max-w-md w-full">
                    <img src="images/open-acc.webp" alt="Open Account" className="max-w-64 mx-auto" />
                </div>
            </div>
        </div>
    )
}