import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import accordion from '../components/pages/json/accordion'

export default function Faqs() {
    return (
        <div className='container mx-auto pt-0 sm:pt-20 pb-20 text-center px-3'>
            <h2 className="font-bold text-3xl text-gray-800 xl:text-5xl mb-14">Frequently Asked Questions</h2>
            
            <Accordion className='text-left' allowZeroExpanded>
                {accordion.map((item, i)=> (
                    <AccordionItem key={i} className='border-b-4 border-[#eee]'>
                        <AccordionItemHeading>
                            <AccordionItemButton className='outline-none font-semibold text-2xl text-[#392557] py-5 flex items-center justify-between aria-expanded:text-[#03880e]'>
                                {item.heading}

                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round" strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 9l6 6l6 -6" /></svg>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className='font-light text-[#4a4a4a] mb-3'>{item.para}</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    )
}
