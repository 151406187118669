import React from 'react';
import Layout from '../Layout';
import Title from '../Title';
import OpenAccount from '../OpenAccount';


export default function Metals() {
    return (
        <Layout>
            <Title name={'Metals'} />

            <div className='container py-20 mx-auto px-3 lg:px-0 flex items-center flex-col sm:flex-row'>
                <div className='sm:w-5/12 mb-8 sm:mb-0'>
                    <img src='images/metals-img.webp' alt='Metals' />
                </div>
                <div className='sm:w-7/12 sm:pl-16'>
                    <h2 className='heading mb-5'>Trading Precious Metals With <span className='text-[#03880e]'>Trade24</span></h2>
                    <p className='text-sm sm:text-base font-light'>The glittery allure of precious metals has been a driving force behind the rise and fall of empires. For centuries, men have fought wars, toiled relentlessly and launched lasting dynasties with precious metals like Gold, Silver, Platinum and more becoming the fuel for their growth. And even in today's modern times, precious metals trading continues to captivate growth seekers and money makers the world over.</p>
                    <p className='text-sm sm:text-base font-light mt-3'>The pull and power of precious metals has remained steadfast over the years, but trading in them has taken on a whole new level of precision and finesse. In today's world of stocks and futures, wealth creators need to understand how CFDs work and keep a finger on the beating pulse of the commodities market in order to succeed as precious metal traders. Reliable trading platforms are imperative for success in these trades.</p>
                </div>
            </div>

            <div className='py-14 sm:py-20 bg-[#f8f9fe]'>
                <div className='container mx-auto text-center px-3 lg:px-0'>
                    <div className='grid grid-cols-2 sm:grid-cols-3 gap-5 sm:gap-7'>
                        <div>
                            <img src="images/trade-over-icon1.webp" alt="trade-over" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Trade MCX & COMEX Metals</p>
                        </div>
                        <div>
                            <img src="images/trade-over-icon2.webp" alt="trade-over" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Competitive Spreads</p>
                        </div>
                        <div>
                            <img src="images/trade-over-icon3.webp" alt="trade-over" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>500x Margin</p>
                        </div>
                        <div>
                            <img src="images/trade-over-icon5.webp" alt="trade-over" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Zero Commission</p>
                        </div>
                        <div>
                            <img src="images/trade-over-icon6.webp" alt="trade-over" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>No Hidden Charges</p>
                        </div>
                        <div>
                            <img src="images/trade-over-icon7.webp" alt="trade-over" className='w-52 mx-auto'/>
                            <p className='font-semibold mt-8'>Open 24 Hours 5 days a week</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container py-20 mx-auto px-3 lg:px-0 flex items-center flex-col sm:flex-row'>
                <div className='sm:w-7/12 sm:pr-16'>
                    <h2 className='heading mb-5'>Trading Precious Metals With <span className='text-[#03880e]'>Trade24</span></h2>
                    <p className='text-sm sm:text-base font-light'>There are a host of good reasons as to why you too should join into the treasure hunt that happens to be precious metals trading. Referred to as the 'Safe-Haven' by investors across the globe, precious metals allow traders to hedge their investments against all kinds of market movements.</p>
                    <p className='text-sm sm:text-base font-light mt-3'>Gold, Silver and other Platinum group metals happen to be the go-to choice for anyone looking for a good investment option. As an asset, they have proven to retain their value in times of political instability, economic uncertainty, dollar weakness, inflation/deflation and even wars. They are always a safe bet for all kinds of investors.</p>
                </div>
                <div className='sm:w-5/12 mt-8 sm:mt-0'>
                    <img src='images/metals-icon2.webp' alt='Metals' />
                </div>
            </div>

            <div className='pb-20'>
                <div className='container mx-auto text-center px-3 lg:px-0'>
                    <h2 className='heading mb-5 max-w-4xl mx-auto'>Why Start Trading Precious Metals With <span className='text-[#03880e]'>Trade24</span></h2>
                    <p className='text-sm sm:text-base font-light'>Trade24 combines diversity, flexibility and safety together to put you on the path towards growth and profitability in your commodities trading journey. Our services let you select your preferred financial instrument, your preferred trading platform as well as your funding source. We will simply prove to be the catalysts that will drive your wagon towards success.</p>
                </div>
            </div>

            <OpenAccount />
        </Layout>
    )
}