import React from 'react';
import Layout from '../Layout';
import Title from '../Title';
import OpenAccount from '../OpenAccount';


export default function Maintainance() {
    return (
        <Layout>
            <Title name={'Maintainance Margins'} />

            <div className='py-20'>
                <div className='container mx-auto px-3 lg:px-0'>
                    <h2 className='heading max-w-4xl text-center mx-auto mb-10'>Maintainance <span className='text-[#03880e]'>Margins</span></h2>

                    <h4 className='text-xl sm:text-2xl font-light mb-2'>What is Overnight or Maintenance margin?</h4>
                    <p className='text-sm sm:text-base font-light mb-10'>This is the amount of funds that the trader must maintain in the account to keep the particular position open or to take particular position to next day..</p>

                    <h4 className='text-xl sm:text-2xl font-light mb-2'>Which exchange or markets required Maintenance margin in Trade24?</h4>
                    <p className='text-sm sm:text-base font-light mb-10'>Indian Exchanges like MCX, NSE, NSE OPTION required less Margin to trade intraday but if you want to trade overnight then you must have overnight or Maintenance margin.</p>

                    <h4 className='text-xl sm:text-2xl font-light mb-2'>What is Margin call level?</h4>
                    <p className='text-sm sm:text-base font-light'>This is the level at which the broker will require the trader to deposit additional funds to maintain the required margin level or to keep account safe from liquidation.</p>
                </div>
            </div>    

            <OpenAccount />
        </Layout>
    )
}