const accordion = [
    { heading: 'What is Trade24?', para: 'Trade24 is Regulated Forex broker with Trade24 you can trade in 8+ exchanges with lower margins.'},
    { heading: 'Why to start trading online?', para: 'It\'s easy, especially with Trade24! Also, make it your additional source of income. Markets are always fluctuating, hence, there is always an opportunity to make money while trading online.'},
    { heading: 'Why Choose Trade24?', para: 'Trade24 provides 8+ exchanges, 500x Margin, 0 brokerage, 500+ Products, 24/5 support in live chat and whatsapp, 4 types of platforms to trade, various deposit methods for payment and various promotional offers.'},
    { heading: 'How to start trading online with Trade24?', para: 'Register on our website or get whatsapp support from both you will get you trading platform username and password less than one minute.'},
    { heading: 'How to deposit my trading account?', para: 'Simply choose a Deposit Menu on your Client\'s Portal, choose your preferred payment method and follow the instructions. Once we receive the funds, your transactions will be approved. Or also you can ask support from whatsapp for the deposit.'},
    { heading: 'How much money do I need to start trading?', para: 'The minimum deposit is 500 INR. But you can keep more on your trading account to have more available free margin.'},
    { heading: 'Can I withdraw my funds at any time?', para: 'Yes, sure! The withdrawal procedure is the same as a deposit. You can withdraw your available balance at any time The procedure is the same as when you deposit, - Simply choose a Withdraw Menu on your Client\'s Portal, choose your preferred withdraw method and follow the instructions. Or also you can ask support from whatsapp for the deposit.'},
    { heading: 'Do you charge any deposit or withdrawal fees?', para: 'Trade24 does not charge any additional fees for deposits.'},
    { heading: 'What is the minimum withdrawal amount?', para: 'The minimum withdrawal is 100 INR for all payment methods.'},
    { heading: 'How does Trade24 support work?', para: 'Here, at Trade24, we believe in our client\'s 100% satisfaction and providing them with all possible service they might need with online chat and whatsapp support.'},
]

export default accordion;