
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'

// const navigation = [
//     {
//       name: "About Us",
//       description: "About Us",
//       href: "#",
//       subMenu: [
//         { name: "Why Trade24?" },
//         { name: "Regulations" },
//         { name: "FAQs" },
//       ],
//     },
//     {
//       name: "Product",
//       description: "Product",
//       href: "#",
//       subMenu: [
//         { name: "Forex" },
//         { name: "Metals" },
//         { name: "Energies" },
//         { name: "Indices" },
//         { name: "Shares" },
//       ],
//     },
//     {
//       name: "Traders",
//       description: "Traders",
//       href: "#",
//       subMenu: [
//         { name: "Maintainance Margin" },
//         { name: "Intraday Margin" },
//       ],
//     },
//     {
//       name: "Platforms",
//       description: "Platforms",
//       href: "#",
//       subMenu: [
//         { name: "" },
//         { name: "" },
//       ],
//     },
//     {
//       name: "Contact Us",
//       description: "Contact Us",
//       href: "#",
//       subMenu: [
//         { name: "" },
//         { name: "" },
//       ],
//     },
// ]

export default function Header() {
    // const [expandedSubMenu, setExpandedSubMenu] = useState(false);
    const [about, setAbout] = useState(false);
    const [product, setProduct] = useState(false);
    const [traders, setTraders] = useState(false);

    const AboutUs = ()=> {
        setAbout(true)
        setProduct(false)
        setTraders(false)
    }
    const Product = ()=> {
        setAbout(false)
        setProduct(true)
        setTraders(false)
    }
    const Traders = ()=> {
        setAbout(false)
        setProduct(false)
        setTraders(true)
    }

    const navigate = useNavigate()
    return (
        <Disclosure as="nav" className="bg-white py-0  sm:py-3">
        {({ open }) => (
            <>
            <div className="mx-auto max-w-[1320px] px-3 sm:px-10 xl:px-2 contaer">
                <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 right-2 flex items-center sm:hidden">
                        {/* <button className='text-[#03880e] text-[11px] font-semibold px-2 py-1 rounded-md border border-[#03880e]'>Log in</button> */}
                        <Link target='_' to={"https://api.whatsapp.com/send?phone=919090782424"}>
                            <button className='bg-[#03880e] text-white text-[11px] font-semibold px-2 py-1 rounded-md mx-3 border border-[#03880e]'>Open Account</button>
                        </Link>
                        <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-0 sm:p-2 focus:outline-none focus:ring-inset focus:ring-white">
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Open main menu</span>
                            {open ? (
                                <FontAwesomeIcon className='h-7 text-[#03880e]' icon={faXmark} />
                            ) : (
                                <FontAwesomeIcon className='h-7 text-[#03880e]' icon={faBars} />
                            )}
                        </DisclosureButton>
                    </div>
                    <div className="flex items-center flex-1">
                        <div className="flex max-w-[135px] cursor-pointer sm:max-w-[200px] xl:max-w-[280px]" onClick={()=>navigate('/')}>
                            <img
                                className="w-full"
                                src="images/logo.png"
                                alt="Logo"
                            />
                        </div>
                        <div className="hidden ml-auto sm:flex items-center">
                            <ul className='flex items-center'>
                                <li className='cursor-pointer px-2 py-9 relative group text-sm xl:text-base'>
                                    <span className='flex items-center hover:text-[#03880e]'>About Us <FontAwesomeIcon icon={faChevronDown} className='text-[10px] ml-1' /></span>
                                    <ul className='Dropdown'>
                                        <li className='subMenu' onClick={()=>navigate('/about')}>Why Trade24?</li>
                                        <li className='subMenu' onClick={()=>navigate('/regulation')}>Regulations</li>
                                        <li className='subMenu' onClick={()=>navigate('/faq')}>FAQs</li>
                                    </ul>
                                </li>
                                <li className='cursor-pointer px-2 py-9 relative group text-sm xl:text-base'>
                                    <span className='flex items-center hover:text-[#03880e]'>Product <FontAwesomeIcon icon={faChevronDown} className='text-[10px] ml-1' /></span>
                                    <ul className='Dropdown'>
                                        <li className='subMenu' onClick={()=>navigate('/forex')}>Forex</li>
                                        <li className='subMenu' onClick={()=>navigate('/metals')}>Metals</li>
                                        <li className='subMenu' onClick={()=>navigate('/energies')}>Energies</li>
                                        <li className='subMenu' onClick={()=>navigate('/indices')}>Indices</li>
                                        <li className='subMenu' onClick={()=>navigate('/shares')}>Shares</li>
                                    </ul>
                                </li>
                                <li className='cursor-pointer px-2 py-9 relative group text-sm xl:text-base'>
                                    <span className='flex items-center hover:text-[#03880e]'>Traders <FontAwesomeIcon icon={faChevronDown} className='text-[10px] ml-1' /></span>
                                    <ul className='Dropdown'>
                                        <li className='subMenu' onClick={()=>navigate('/maintainance')}>Maintainance Margin</li>
                                        <li className='subMenu' onClick={()=>navigate('/intraday')}>Intraday Margin</li>
                                    </ul>
                                </li>
                                {/* <li className='cursor-pointer px-2 py-9 group text-sm xl:text-base hover:text-[#03880e]' onClick={()=>navigate('/platform')}>
                                    <span className='flex items-center'>Platforms</span>                                    
                                </li> */}
                                <li className='cursor-pointer px-2 py-9 group text-sm xl:text-base hover:text-[#03880e]' onClick={()=>navigate('/contact')}>
                                    <span className='flex items-center'>Contact Us</span>                                    
                                </li>
                            </ul>
                            <div className='ml-0 xl:ml-5'>
                                {/* <button className='headerBtn group'><span className='relative group-hover:text-white'><FontAwesomeIcon icon={faWhatsapp} className='text-lg mr-1' /> Get Account</span></button>
                                <button className='headerBtn group'><span className='relative group-hover:text-white'>Log in</span></button> */}

                                <Link target='_' to={"https://api.whatsapp.com/send?phone=919090782424"}>
                                    <button className='headerSolidBtn group'><span className='relative group-hover:text-[#03880e]'>Open Account</span></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DisclosurePanel className="sm:hidden">
                <ul className='px-3'>
                    <li className='cursor-pointer relative group bg-[#03880e] text-white text-sm font-semibold px-3 py-2 mb-3 rounded-md' onClick={AboutUs}>
                        <span className='flex items-center justify-between'>About Us <FontAwesomeIcon icon={faChevronDown} className='text-xs' /></span>
                    </li>
                    {about ? 
                    <ul className='bg-[#03880e] text-white text-sm font-semibold px-3 py-2 mb-3 rounded-md'>
                        <li className='py-1' onClick={()=>navigate('/about')}>Why Trade24?</li>
                        <li className='py-1' onClick={()=>navigate('/regulation')}>Regulations</li>
                        <li className='py-1' onClick={()=>navigate('/faq')}>FAQs</li>
                    </ul>
                    : null}
                    <li className='cursor-pointer relative group bg-[#03880e] text-white text-sm font-semibold px-3 py-2 mb-3 rounded-md' onClick={Product}>
                        <span className='flex items-center justify-between'>Product <FontAwesomeIcon icon={faChevronDown} className='text-xs' /></span>
                    </li>
                    {product ? 
                    <ul className='bg-[#03880e] text-white text-sm font-semibold px-3 py-2 mb-3 rounded-md'>
                        <li className='py-1' onClick={()=>navigate('/forex')}>Forex</li>
                        <li className='py-1' onClick={()=>navigate('/metals')}>Metals</li>
                        <li className='py-1' onClick={()=>navigate('/energies')}>Energies</li>
                        <li className='py-1' onClick={()=>navigate('/indices')}>Indices</li>
                        <li className='py-1' onClick={()=>navigate('/shares')}>Shares</li>
                    </ul>
                    : null}
                    <li className='cursor-pointer relative group bg-[#03880e] text-white text-sm font-semibold px-3 py-2 mb-3 rounded-md' onClick={Traders}>
                        <span className='flex items-center justify-between'>Traders <FontAwesomeIcon icon={faChevronDown} className='text-xs' /></span>
                    </li>
                    {traders ? 
                    <ul className='bg-[#03880e] text-white text-sm font-semibold px-3 py-2 mb-3 rounded-md'>
                        <li className='py-1' onClick={()=>navigate('/maintainance')}>Maintainance Margin</li>
                        <li className='py-1' onClick={()=>navigate('/intraday')}>Intraday Margin</li>
                    </ul>
                    : null}
                    {/* <li className='cursor-pointer group bg-[#03880e] text-white text-sm font-semibold px-3 py-2 mb-3 rounded-md' onClick={()=>navigate('/platform')}>
                        <span className='flex items-center'>Platforms</span>                                    
                    </li> */}
                    <li className='cursor-pointer group bg-[#03880e] text-white text-sm font-semibold px-3 py-2 mb-3 rounded-md' onClick={()=>navigate('/contact')}>
                        <span className='flex items-center'>Contact Us</span>                                    
                    </li>
                </ul>
                {/* <div className='flex px-3 mb-5'>
                    <button className='font-semibold -textsm rounded-lg px-4 py-3 bg-[#03880e] text-white w-6/12 mr-2'>Log in</button>
                    <button className='font-semibold text-sm rounded-lg px-4 py-3 bg-[#03880e] text-white w-6/12 ml-2'>Open Account</button>
                </div> */}

            {/* {navigation.map((item) => (
                <div className='px-3'>
                <div
                    onClick={() => setExpandedSubMenu(item.description)}
                    className="cursor-pointer relative group bg-[#03880e] text-white text-sm font-semibold px-3 py-2 mb-1 rounded-md"
                >
                    <span className='flex items-center justify-between'>{item.description} <FontAwesomeIcon icon={faChevronDown} className={expandedSubMenu !== item.description ? "text-xs" : "text-xs rotate-180"}/></span>
                </div>

                <div className={expandedSubMenu !== item.description ? "none" : "flex flex-col bg-[#03880e] mb-2 py-2 rounded-md"}>
                    {item.subMenu.map((sm) => (
                        <div key={sm.name} className={expandedSubMenu !== item.description ? "hidden" : " text-white text-sm font-semibold px-3 py-2"}>
                            {sm.name}
                        </div>
                    ))}
                </div>
                </div>
            ))} */}
            </DisclosurePanel>
            </>
        )}
        </Disclosure>
    )
}