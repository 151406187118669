import React from 'react';
import Layout from '../Layout';
import Title from '../Title';
import StartTrading from '../StartTrading';


export default function About() {
    return (
        <Layout>
            <Title name={'About Trade24'} />
            
            <div className='py-20 mx-3 sm:mx-0'>
                <div className='container mx-auto p-5 border border-gray-200 rounded-[10px] text-center'>
                    <h2 className='heading text-center mb-3'>About us</h2>
                    <p className='text-sm sm:text-lg font-light mb-6'>Trade24 is an online trading brokerage firm founded in 2020 by industry leading experts with a shared commitment to share their expertise and improve the world of financial markets. Over the past three years we have grown to a group of companies serving more than 8,000 clients in 42+ countries.</p>
                    <p className='text-sm sm:text-lg font-light'>Our goal is to become the number one brokerage firm by user experience and our clients satisfaction. Welcome to the Trade24 family!</p>
                </div>
            </div>

            <div className='py-20 bg-gray-100 text-[#4a4a4a]'>
                <div className='container mx-auto px-3 lg:px-0'>
                    <h2 className='heading text-center'>Why choose <span className='text-[#03880e]'>Trade24?</span></h2>
                    <div className='mt-11 grid grid-cols-1 sm:grid-cols-3 gap-x-7 gap-y-10'>
                        <div className='bg-white shadow-lg p-5 rounded-lg transition-all group hover:-mt-3 duration-500'>
                            <div className='bg-gradient-to-r from-[#00b30c] to-[#03880e] rounded-t-2xl w-full h-56 flex items-center justify-center mb-5'>
                                <img src="images/cool.png" alt="Cool" className='max-w-[180px] transition-all duration-500 group-hover:scale-x-[-1]' />
                            </div>
                            <h4 className='text-2xl font-semibold mb-2'>1</h4>
                            <h5 className='text-xl font-semibold mb-2'>All Exchange in One Account</h5>
                            <p className='text-sm sm:text-base font-light'>Choose between Standard, No Swap account, Zero spread account or a Pro trading account.</p>
                        </div>
                        <div className='bg-white shadow-lg p-5 rounded-lg transition-all group hover:-mt-3 duration-500'>
                            <div className='bg-gradient-to-r from-[#00b30c] to-[#03880e] rounded-t-2xl w-full h-56 flex items-center justify-center mb-5'>
                                <img src="images/safe.png" alt="safe" className='max-w-[180px] transition-all duration-500 group-hover:scale-x-[-1]' />
                            </div>
                            <h4 className='text-2xl font-semibold mb-2'>2</h4>
                            <h5 className='text-xl font-semibold mb-2'>Licensed and Regulated.</h5>
                            <p className='text-sm sm:text-base font-light'>It is very important for us to work within a clear framework of the law and provide the protection of the regulator to our clients.</p>
                        </div>
                        <div className='bg-white shadow-lg p-5 rounded-lg transition-all group hover:-mt-3 duration-500'>
                            <div className='bg-gradient-to-r from-[#00b30c] to-[#03880e] rounded-t-2xl w-full h-56 flex items-center justify-center mb-5'>
                                <img src="images/check.png" alt="check" className='max-w-[180px] transition-all duration-500 group-hover:scale-x-[-1]' />
                            </div>
                            <h4 className='text-2xl font-semibold mb-2'>3</h4>
                            <h5 className='text-xl font-semibold mb-2'>Easy onboarding process.</h5>
                            <p className='text-sm sm:text-base font-light'>We know how to make your onboarding user friendly and with minimum paperwork, but to comply with regulations at the same time.</p>
                        </div>
                        <div className='bg-white shadow-lg p-5 rounded-lg transition-all group hover:-mt-3 duration-500'>
                            <div className='bg-gradient-to-r from-[#00b30c] to-[#03880e] rounded-t-2xl w-full h-56 flex items-center justify-center mb-5'>
                                <img src="images/rocket.png" alt="rocket" className='max-w-[180px] transition-all duration-500 group-hover:scale-x-[-1]' />
                            </div>
                            <h4 className='text-2xl font-semibold mb-2'>4</h4>
                            <h5 className='text-xl font-semibold mb-2'>Ultra-fast execution.</h5>
                            <p className='text-sm sm:text-base font-light'>Most of your trades are filled in less than 20 ms of time. Wow!</p>
                        </div>
                        <div className='bg-white shadow-lg p-5 rounded-lg transition-all group hover:-mt-3 duration-500'>
                            <div className='bg-gradient-to-r from-[#00b30c] to-[#03880e] rounded-t-2xl w-full h-56 flex items-center justify-center mb-5'>
                                <img src="images/crow.png" alt="crow" className='max-w-[180px] transition-all duration-500 group-hover:scale-x-[-1]' />
                            </div>
                            <h4 className='text-2xl font-semibold mb-2'>5</h4>
                            <h5 className='text-xl font-semibold mb-2'>Best trading platform.</h5>
                            <p className='text-sm sm:text-base font-light'>For our beloved clients we provide the world leading trading platform - MetaTrader 5.</p>
                        </div>
                        <div className='bg-white shadow-lg p-5 rounded-lg transition-all group hover:-mt-3 duration-500'>
                            <div className='bg-gradient-to-r from-[#00b30c] to-[#03880e] rounded-t-2xl w-full h-56 flex items-center justify-center mb-5'>
                                <img src="images/present.png" alt="present" className='max-w-[180px] transition-all duration-500 group-hover:scale-x-[-1]' />
                            </div>
                            <h4 className='text-2xl font-semibold mb-2'>6</h4>
                            <h5 className='text-xl font-semibold mb-2'>Variety of products.</h5>
                            <p className='text-sm sm:text-base font-light'>We provide you with a range of 270 trading instruments amongst 5 asset classes.</p>
                        </div>
                    </div>                    
                </div>
            </div>

            <StartTrading />

        </Layout>
    )
}