import { About, Contact, Energies, Faq, Forex, Home, Indices, Intraday, Maintainance, Metals, Platform, Regulation, Shares } from "./components"

let routes = [
    {
        exact: true,
        path: '/',
        component: Home,
    },
    {
        exact: true,
        path: '/about',
        component: About,
    },
    {
        exact: true,
        path: '/regulation',
        component: Regulation,
    },
    {
        exact: true,
        path: '/faq',
        component: Faq,
    },
    {
        exact: true,
        path: '/forex',
        component: Forex,
    },
    {
        exact: true,
        path: '/metals',
        component: Metals,
    },
    {
        exact: true,
        path: '/energies',
        component: Energies,
    },
    {
        exact: true,
        path: '/indices',
        component: Indices
    },
    {
        exact: true,
        path: '/shares',
        component: Shares
    },
    {
        exact: true,
        path: '/maintainance',
        component: Maintainance
    },
    {
        exact: true,
        path: '/intraday',
        component: Intraday
    },
    {
        exact: true,
        path: '/platform',
        component: Platform
    },
    {
        exact: true,
        path: '/contact',
        component: Contact
    },
]

export default routes