import React from 'react';
import Layout from '../Layout';
import Title from '../Title';
import OpenAccount from '../OpenAccount';


export default function Intraday() {
    return (
        <Layout>
            <Title name={'Intraday Margins'} />

            <div className='py-20'>
                <div className='container mx-auto px-3 lg:px-0'>
                    <h2 className='heading max-w-4xl text-center mx-auto mb-10'>Intraday <span className='text-[#03880e]'>Margins</span></h2>

                    <h4 className='text-xl sm:text-2xl font-light mb-2'>What is Intraday margin?</h4>
                    <p className='text-sm sm:text-base font-light mb-10'>This is the amount of funds that the trader must deposit or is required to open a particular position</p>

                    <h4 className='text-xl sm:text-2xl font-light mb-2'>Which Exchange or Markets have same Intraday and Maintenance margin in Trade24?</h4>
                    <p className='text-sm sm:text-base font-light mb-10'>Exchanges like FOREX required same Margin to trade intraday and overnight which is very less.</p>

                    <h4 className='text-xl sm:text-2xl font-light mb-2'>What is the intraday Margin in all Markets?</h4>
                    <p className='text-sm sm:text-base font-light'>Trade24 offers 500x Margin to trade intraday in MCX, NSE, FOREX and COMEX</p>
                </div>
            </div>    

            <OpenAccount />
        </Layout>
    )
}