import React from 'react';
import Layout from '../Layout';
import Title from '../Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function Contact() {
    return (
        <Layout>
            <Title name={'Contact'} />

            <div className='py-10 smpy-20 container mx-auto px-3 sm:px-0 flex flex-wrap'>
                <div className='w-full sm:w-7/12 pr-0 sm:pr-7'>
                    <h4 className='text-sm sm:text-base text-[#03880e] font-medium mb-1'>Send us email</h4>
                    <h3 className='text-2xl sm:text-3xl font-bold mb-1'>Feel free to write</h3>

                    <form>
                        <div className='mt-5 grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-4 mb-4'>
                            <input type='text' className='bg-[#f4f5f8] rounded-md w-full h-12 font-light py-3 px-6 text-sm' placeholder='Enter Name' />
                            <input type='email' className='bg-[#f4f5f8] rounded-md w-full h-12 font-light py-3 px-6 text-sm' placeholder='Enter Email' />
                            <input type='text' className='bg-[#f4f5f8] rounded-md w-full h-12 font-light py-3 px-6 text-sm' placeholder='Enter Subject' />
                            <input type='number' className='bg-[#f4f5f8] rounded-md w-full h-12 font-light py-3 px-6 text-sm' placeholder='Enter Phone' />
                        </div>
                        <textarea className='bg-[#f4f5f8] rounded-md w-full font-light py-3 px-6 text-sm' rows={'7'} placeholder='Enter Message'></textarea>
                        <button className='solidBtn mt-3'>Send message</button>
                    </form>
                </div>
                <div className='w-full sm:w-5/12 mt-10 sm:mt-0'>
                    <h4 className='text-sm sm:text-base text-[#03880e] font-medium mb-1'>Need any help?</h4>
                    <h3 className='text-2xl sm:text-3xl font-bold mb-1'>Get in touch with us</h3>

                    <div className='mt-5 flex items-center'>
                        <div className='border-2 border-[#03880e] w-14 h-14 rounded-md flex justify-center items-center mr-5'><FontAwesomeIcon icon={faPhoneVolume} className='text-2xl text-[#03880e]' /></div>
                        <div>
                            <h5 className='font-semibold text-sm sm:text-base mb-1'>Have any question?</h5>
                            <Link target='_' to={"https://api.whatsapp.com/send?phone=919090782424"}>
                                <p className="font-light text-sm">+91 90907 82424</p>                       
                                <p className="font-light text-sm">+91 90905 72424</p>  
                            </Link>                     
                        </div>
                    </div>
                    <div className='mt-5 flex items-center'>
                        <div className='border-2 border-[#03880e] w-14 h-14 rounded-md flex justify-center items-center mr-5'><FontAwesomeIcon icon={faEnvelope} className='text-2xl text-[#03880e]' /></div>
                        <div>
                            <h5 className='font-semibold text-sm sm:text-base mb-1'>Write email</h5>
                            <Link target="_" to={'mailto:support@trade24live.com'}>
                                <p className='font-light text-sm'>support@trade24live.com</p>
                            </Link>   
                        </div>
                    </div>
                    <div className='mt-5 flex items-center'>
                        <div className='border-2 border-[#03880e] w-14 h-14 rounded-md flex justify-center items-center mr-5'><FontAwesomeIcon icon={faLocationDot} className='text-2xl text-[#03880e]' /></div>
                        <div>
                            <h5 className='font-semibold text-sm sm:text-base mb-1'>Registered Address</h5>
                            <p className='font-light text-sm'>21/23, Al Hamriya Street, Dubai, UAE</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}