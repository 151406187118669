import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Layout from '../Layout';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import Faqs from '../Faqs';
import { Link } from 'react-router-dom';


export default function Home() {
    const swiperRef = useRef();
    const [showDetail, setShowDetail] = useState(false)

    const detailHandler = () => {
        setShowDetail(!showDetail)
    }

    const pagination = {
        clickable: true,
        bulletActiveClass: 'bg-[#fff]',
        clickableClass: 'flex item-center justify-center absolute h-5',
        renderBullet: function (index, className) {
            return `<div class="w-3 h-3 rounded border-[#fff] border-[1px] mx-3 bg-[#03880e] cursor-pointer opacity-100 ${index ? 'active' : ''} ${className}"></div>`;
        },
    };

    return (
        <Layout>
            <div className="bg-[#03880e] w-full min-h-[78vh] flex items-center relative pt-10 pb-32">
                <Swiper pagination={pagination} modules={[Navigation, Pagination]} loop={true} onBeforeInit={(swiper) => {swiperRef.current = swiper}}>
                    <SwiperSlide>
                        <div className='px-3 max-w-[720px] xl:max-w-[870px] mx-auto text-center pt-12 pb-16'>
                            <h1 className="text-3xl sm:text-[56px] xl:text-7xl text-white font-bold mb-8 xl:leading-snug">Enjoy Zero Brokerage in  All below Markets.</h1>
                            <ul className="flex justify-center">
                                <li><span className="text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">MCX</span></li>
                                <li><span className="text-white text-lg sm:text-[22px] xl:text-3xl font-semibold px-2">|</span></li>
                                <li><span className="text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">NSE Future</span></li>
                                <li><span className="text-white text-lg sm:text-[22px] xl:text-3xl font-semibold px-2">|</span></li>
                                <li><span className="text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">INDEX Options</span></li>
                            </ul>
                            <ul className="flex justify-center">
                                <li><span className="text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">Forex</span></li>
                                <li><span className="text-white text-lg sm:text-[22px] xl:text-3xl font-semibold px-2">|</span></li>
                                <li><span className="text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">Comex</span></li>
                            </ul>
                            <ul className="flex justify-center">
                                <li><span className="text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">Crypto</span></li>
                                <li><span className="text-white text-lg sm:text-[22px] xl:text-3xl font-semibold px-2">|</span></li>
                                <li><span className="text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">Global Indices</span></li>
                                <li><span className="text-white text-lg sm:text-[22px] xl:text-3xl font-semibold px-2">|</span></li>
                                <li><span className="text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">US Stocks</span></li>
                            </ul>
                            <p className="text-sm text-white mt-7 mb-8 font-light">Explore the world of financial markets with Trade24</p>
                            <Link target='_' to={"https://api.whatsapp.com/send?phone=919090782424"}>
                                <button className="border p-3 text-xs sm:text-base border-white rounded-lg text-[#fff] sm:px-7 py-3 font-light transition-all hover:bg-white hover:text-[#03880e]">Sign up Now</button>
                            </Link>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='px-3 max-w-[720px] xl:max-w-[870px] mx-auto text-center pt-12 pb-16'>
                            <h1 className="text-3xl sm:text-[56px] xl:text-7xl text-white font-bold mb-8 xl:leading-snug">Don't just Trade. Be the Bull with Trade24!</h1>
                            <ul className="flex justify-center flex-wrap">
                                <li><span className="whitespace-nowrap text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">Licensed &amp; Regulated</span></li>
                                <li><span className="whitespace-nowrap text-white text-lg sm:text-[22px] xl:text-3xl font-semibold px-2">|</span></li>
                                <li><span className="whitespace-nowrap text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">8+ Markets</span></li>
                                <li><span className="whitespace-nowrap text-white text-lg sm:text-[22px] xl:text-3xl font-semibold px-2">|</span></li>
                                <li><span className="whitespace-nowrap text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">500+ Instruments</span></li>
                            </ul>
                            <ul className="flex justify-center flex-wrap">
                                <li><span className="whitespace-nowrap text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">500x Margin</span></li>
                                <li><span className="whitespace-nowrap text-white text-lg sm:text-[22px] xl:text-3xl font-semibold px-2">|</span></li>
                                <li><span className="whitespace-nowrap text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">Referral Programs</span></li>
                                <li><span className="whitespace-nowrap text-white text-lg sm:text-[22px] xl:text-3xl font-semibold px-2">|</span></li>
                                <li><span className="whitespace-nowrap text-white text-lg sm:text-[22px] xl:text-3xl font-semibold">24/5 Support</span></li>
                            </ul>
                            <p className="text-sm text-white mt-7 mb-8 font-light">Enjoy all key benefits of Trade24 in your trading journey.</p>
                            <Link target='_' to={"https://api.whatsapp.com/send?phone=919090782424"}>
                                <button className="border p-3 text-xs sm:text-base border-white rounded-lg text-[#fff] sm:px-7 py-3 font-light transition-all hover:bg-white hover:text-[#03880e]">Sign up Now</button>
                            </Link>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='px-3 max-w-[720px] xl:max-w-[870px] mx-auto text-center pt-12 pb-16'>
                            <h1 className="text-3xl sm:text-[56px] xl:text-7xl text-white font-bold mb-8 xl:leading-snug">Bonus Referral.</h1>
                            <span className=" text-lgsm:text-[22px] xl:text-3xl text-white mb-8 font-bold">When you register your friend through you on Trade24 you will get 500/- bonus on each referral, credited into your account.</span>
                            <p className="text-sm text-white mt-7 mb-8 font-light">Explore more opportunites with Trade24.</p>
                            <Link target='_' to={"https://api.whatsapp.com/send?phone=919090782424"}>
                                <button className="border p-3 text-xs sm:text-base border-white rounded-lg text-[#fff] sm:px-7 py-3 font-light transition-all hover:bg-white hover:text-[#03880e]">Contact us</button>
                            </Link>
                        </div>
                    </SwiperSlide>
                    <div className='w-full hidden sm:flex absolute z-50 my-auto top-[50%]'>
                        <button onClick={() => swiperRef.current?.slidePrev()} className='ml-10'><img src='images/left-arrow-white.png' alt='left-arrow-white' /></button>
                        <button onClick={() => swiperRef.current?.slideNext()} className='mr-10 ml-auto'><img src='images/right-arrow-white.png' alt='right-arrow-white' /></button>
                    </div>
                </Swiper>
            </div>

            <div className='max-w-[1140px] mx-auto lg:container px-3 sm:px-0'>
                <div className='bg-white rounded-[30px] shadow-xl -mt-24 relative z-50 overflow-hidden justify-between px-3 grid grid-cols-3 lg:flex lg:grid-cols-none'>
                    <div className='basis-full text-center cursor-pointer py-3 sm:py-10 px-3 hover:bg-[#e7ffe5]'>
                        <h2 className='text-[#03880e] font-semibold text-2xl sm:text-3xl mb-3'>0</h2>
                        <h4 className='text-sm sm:text-base font-semibold'>Brokerage</h4>
                    </div>
                    <div className='basis-full text-center cursor-pointer py-3 sm:py-10 px-3 hover:bg-[#e7ffe5]'>
                        <h2 className='text-[#03880e] font-semibold text-2xl sm:text-3xl mb-3'>500x</h2>
                        <h4 className='text-sm sm:text-base font-semibold'>Margin</h4>
                    </div>
                    <div className='basis-full text-center cursor-pointer py-3 sm:py-10 px-3 hover:bg-[#e7ffe5]'>
                        <h2 className='text-[#03880e] font-semibold text-2xl sm:text-3xl mb-3'>Referral</h2>
                        <h4 className='text-sm sm:text-base font-semibold'>Bonus</h4>
                    </div>
                    <div className='basis-full text-center cursor-pointer py-3 sm:py-10 px-3 hover:bg-[#e7ffe5]'>
                        <h2 className='text-[#03880e] font-semibold text-2xl sm:text-3xl mb-3'>24/5</h2>
                        <h4 className='text-sm sm:text-base font-semibold'>Support</h4>
                    </div>
                    <div className='basis-full text-center cursor-pointer py-3 sm:py-10 px-3 hover:bg-[#e7ffe5]'>
                        <img src='images/license.png' alt='License' className='w-10 mb-3 mx-auto' />
                        <h4 className='text-sm sm:text-base font-semibold'>Regulated Broker</h4>
                    </div>
                    <div className='basis-full text-center cursor-pointer py-3 sm:py-10 px-3 hover:bg-[#e7ffe5]'>
                        <img src='images/market.png' alt='Market' className='w-10 mb-3 mx-auto' />
                        <h4 className='text-sm sm:text-base font-semibold'>Indian Markets</h4>
                    </div>
                    <div className='basis-full text-center cursor-pointer py-3 sm:py-10 px-3 hover:bg-[#e7ffe5]'>
                        <h2 className='text-[#03880e] font-semibold text-2xl sm:text-3xl mb-3'>500+</h2>
                        <h4 className='text-sm sm:text-base font-semibold'>Products</h4>
                    </div>
                    <div className='basis-full text-center cursor-pointer py-3 sm:py-10 px-3 hover:bg-[#e7ffe5]'>
                        <h2 className='text-[#03880e] font-semibold text-2xl sm:text-3xl mb-3'>10 Sec</h2>
                        <h4 className='text-sm sm:text-base font-semibold'>To Register</h4>
                    </div>
                    <div className='basis-full text-center cursor-pointer py-3 sm:py-10 px-3 hover:bg-[#e7ffe5]'>
                        <img src='images/kyc.png' alt='Kyc' className='w-10 mb-3 mx-auto' />
                        <h4 className='text-sm sm:text-base font-semibold'>No Wait</h4>
                    </div>
                </div>
            </div>
            

            <div className='bg-[#e7ffe5] max-w-[1140px] mx-5 sm:mx-auto lg:container flex items-center rounded-t-[30px] sm:rounded-t-[0px] rounded-b-[30px] rounded-l-b-[30px] px-5 sm:px-20 py-10 sm:py-28 mt-20 sm:-mt-10 flex-wrap'>
                <div className='w-full sm:w-[45%] sm:pr-5'>
                    <h2 className="heading">Trade With Trust</h2>
                    <h2 className="font-normal text-3xl text-gray-800">Anytime , Anywhere</h2>
                    <p className="font-semibold text-base text-gray-800 my-8">500+ Instruments are available for trading with two famous platforms under one roof.</p>
                    <h3 className="font-semibold text-lg text-[#392557] mb-8">Available platform</h3>
                    <div className='grid grid-cols-2 gap-5'>
                        <a className='flex items-center' href={'./apk/com.trade24live.in.apk'} download={'./apk/com.trade24live.in.apk'}>
                            <img src='images/plays.png' alt='Platform' className='w-6 mr-3' /> <span className="font-semibold">Playstore</span>
                        </a>
                        <a href='https://www.trade24live.in' className='flex items-center'><img src='images/apphome.png' alt='Platform' className='w-6 mr-3' /> <span className="font-semibold">Apple Store</span></a>
                        <a href='https://www.trade24live.in' className='flex items-center'><img src='images/desapp.png' alt='Platform' className='w-6 mr-3' /> <span className="font-semibold">Desktop App</span></a>
                        <a href='https://www.trade24live.in' className='flex items-center'><img src='images/htppss.png' alt='Platform' className='w-6 mr-3' /> <span className="font-semibold">Web Trader</span></a>
                    </div>
                    <br/>
                    <Link target='_' to={"https://api.whatsapp.com/send?phone=919090782424"}>
                        <button className='solidBtn mt-10'>Start Trading</button>
                    </Link>

                    <button className='solidBtn mt-10 ml-5 bg-transparent text-[#03880e] hover:bg-white' onClick={detailHandler}>Login Details</button>
                </div>
                <div className='w-full mt-8 sm:mt-0 sm:w-[55%]'>
                    <img src='images/graph-thumnail.png' alt='Graph' />
                </div>
            </div>

            {showDetail ? 
                <div className='fixed top-0 left-0 h-full w-full bg-overlay z-50 flex items-center justify-center' aria-hidden="true">
                    <div className='max-w-[400px] w-full bg-white border border-[#03880e] mt-6 rounded-md py-10 px-5'>
                        <span className='font-semibold text-lg text-[#03880e]'>Demo Login Credentials -</span>
                        <div className='flex items-center mt-3'>
                            <p className='text-lg'>Id: Demo2412</p>
                            <p className='text-lg ml-4'>Password: 123456</p>
                        </div>

                        <div className='pt-7 mt-10 border-t text-center'>
                            <buttom className="solidBtn px-10 cursor-pointer" onClick={()=>setShowDetail(!showDetail)}>Ok</buttom>
                        </div>
                    </div>
                </div>
            : null}

            <div className='pr-10 pt-20 flex pl-10 pb-20 sm:pl-0 sm:pb-0'>
                <div className='w-[45%] relative max-h-[650px] overflow-hidden xl:max-h-[750px] hidden sm:block'>
                    <img src="images/trade-with-us.png" alt='Trade-with-us' className='w-full relative -left-10 xl:w-[85%]' />
                </div>
                <div className='w-full sm:w-[40%] pl-0 sm:pl-20 sm:mb-5'>
                    <h2 className="heading">Available Markets</h2>
                    <ul className='mt-8'>
                        <li className='flex items-center mb-5'><img src='images/check.svg' alt='Checkbox' className='w-6 mr-3' /> <span className='font-semibold text-lg sm:text-2xl text-gray-700'>MCX</span></li>
                        <li className='flex items-center mb-5'><img src='images/check.svg' alt='Checkbox' className='w-6 mr-3' /> <span className='font-semibold text-lg sm:text-2xl text-gray-700'>NSE Future</span></li>
                        <li className='flex items-center mb-5'><img src='images/check.svg' alt='Checkbox' className='w-6 mr-3' /> <span className='font-semibold text-lg sm:text-2xl text-gray-700'>INDEX Options</span></li>
                        <li className='flex items-center mb-5'><img src='images/check.svg' alt='Checkbox' className='w-6 mr-3' /> <span className='font-semibold text-lg sm:text-2xl text-gray-700'>Forex</span></li>
                        <li className='flex items-center mb-5'><img src='images/check.svg' alt='Checkbox' className='w-6 mr-3' /> <span className='font-semibold text-lg sm:text-2xl text-gray-700'>Comex</span></li>
                        <li className='flex items-center mb-5'><img src='images/check.svg' alt='Checkbox' className='w-6 mr-3' /> <span className='font-semibold text-lg sm:text-2xl text-gray-700'>Crypto</span></li>
                        <li className='flex items-center mb-5'><img src='images/check.svg' alt='Checkbox' className='w-6 mr-3' /> <span className='font-semibold text-lg sm:text-2xl text-gray-700'>US Stocks</span></li>
                    </ul>
                    <Link target='_' to={"https://api.whatsapp.com/send?phone=919090782424"}>
                        <button className='solidBtn mt-5 sm:mt-10'>Start Trading</button>
                    </Link>
                </div>
            </div>

            <div className='py-24 bg-brokerage bg-cover bg-right relative before:content-[""] before:w-full before:h-full before:bg-[#03880e] before:bg-opacity-60 before:absolute before:top-0 before:left-0 before:z-10'>
                <div className='container mx-auto text-center relative z-30 px-3'>
                    <h2 className='font-bold text-3xl text-white xl:text-5xl'>Sign up now and trade with <span className='text-3xl sm:text-7xl'>0</span> brokerage</h2>
                    <div className='mt-20 sm:mt-32 flex justify-center flex-wrap'>
                        <div className='px-3 py-3 sm:px-9 sm:py-3 mx-5 rounded-2xl cursor-pointer bg-white flex items-center w-full sm:w-auto'><img src='images/24-hours-service-icon.png' alt='24/7' className='w-9 sm:w-auto' /> <span className='text-[#392557] font-semibold ml-3 text-sm sm:text-2xl'>24/5 Hours services</span></div>
                        <Link target='_' to={"https://api.whatsapp.com/send?phone=919090782424"}>
                            <div className='px-3 py-3 sm:px-9 sm:py-3 mx-0 lg:mx-5 rounded-2xl cursor-pointer bg-white flex items-center w-full sm:w-auto mt-10 lg:mt-0'>
                                <img src='images/whatsapp-icon.png' alt='24/7' className='w-9 sm:w-auto' /> 
                                <span className='text-[#392557] font-semibold ml-3 text-sm sm:text-2xl'>+91 90907 82424</span>
                                <span className='text-[#392557] font-semibold mx-5 text-sm sm:text-2xl'>|</span>
                                <span className='text-[#392557] font-semibold text-sm sm:text-2xl'>+91 90905 72424</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className='container mx-auto sm:text-center py-20 px-3'>
                <h2 className="heading text-center">Why Trade24?</h2>
                <div className='grid gap-y-10 grid-cols-1 sm:grid-cols-3 mt-14'>
                    <div className='px-3 flex items-start sm:block'>
                        <img src='images/register-icon.png' alt='register-icon' className='mx-auto w-11 sm:w-auto' />
                        <div className='ml-4 sm:ml-0'>
                            <h2 className='text-lg sm:text-2xl mt-0 sm:mt-8 mb-1 sm:mb-3 font-semibold text-gray-700'>Register in 10 Sec</h2>
                            <p className='font-light text-[#4a4a4a] text-sm sm:text-base'>Create your online account in our portal in less than 10 seconds with our automated wallet and start playing.</p>
                        </div>
                    </div>
                    <div className='px-3 flex items-start sm:block sm:border-x-2'>
                        <img src='images/support-icon.png' alt='support-icon' className='mx-auto w-11 sm:w-auto' />
                        <div className='ml-4 sm:ml-0'>
                            <h2 className='text-lg sm:text-2xl mt-0 sm:mt-8 mb-1 sm:mb-3 font-semibold text-gray-700'>24/5 Support</h2>
                            <p className='font-light text-[#4a4a4a] text-sm sm:text-base'>Create your online account in our portal in less than 10 seconds with our automated wallet and start playing.</p>
                        </div>
                    </div>
                    <div className='px-3 flex items-start sm:block'>
                        <img src='images/trusted-icon.png' alt='trusted-icon' className='mx-auto w-11 sm:w-auto' />
                        <div className='ml-4 sm:ml-0'>
                            <h2 className='text-lg sm:text-2xl mt-0 sm:mt-8 mb-1 sm:mb-3 font-semibold text-gray-700'>100% Trusted service</h2>
                            <p className='font-light text-[#4a4a4a] text-sm sm:text-base'>Create your online account in our portal in less than 10 seconds with our automated wallet and start playing.</p>
                        </div>
                    </div>
                    <div className='px-3 flex items-start sm:block'>
                        <img src='images/wallet-icon.png' alt='wallet-icon' className='mx-auto w-11 sm:w-auto' />
                        <div className='ml-4 sm:ml-0'>
                            <h2 className='text-lg sm:text-2xl mt-0 sm:mt-8 mb-1 sm:mb-3 font-semibold text-gray-700'>Instant withdrawal & deposit</h2>
                            <p className='font-light text-[#4a4a4a] text-sm sm:text-base'>Create your online account in our portal in less than 10 seconds with our automated wallet and start playing.</p>
                        </div>
                    </div>
                    <div className='px-3 flex items-start sm:block sm:border-x-2'>
                        <img src='images/payment-icon.png' alt='payment-icon' className='mx-auto w-11 sm:w-auto' />
                        <div className='ml-4 sm:ml-0'>
                            <h2 className='text-lg sm:text-2xl mt-0 sm:mt-8 mb-1 sm:mb-3 font-semibold text-gray-700'>Payment methods</h2>
                            <p className='font-light text-[#4a4a4a] text-sm sm:text-base'>Create your online account in our portal in less than 10 seconds with our automated wallet and start playing.</p>
                        </div>
                    </div>
                    <div className='px-3 flex items-start sm:block'>
                        <img src='images/data-security-icon.png' alt='data-security-icon' className='mx-auto w-11 sm:w-auto' />
                        <div className='ml-4 sm:ml-0'>
                            <h2 className='text-lg sm:text-2xl mt-0 sm:mt-8 mb-1 sm:mb-3 font-semibold text-gray-700'>Data security</h2>
                            <p className='font-light text-[#4a4a4a] text-sm sm:text-base'>Create your online account in our portal in less than 10 seconds with our automated wallet and start playing.</p>
                        </div>
                    </div>
                </div>
            </div>

            <Faqs />

            <div className='py-16 sm:py-24 bg-journey bg-cover bg-right relative before:content-[""] before:w-full before:h-full before:bg-[#03880e] before:bg-opacity-60 before:absolute before:top-0 before:left-0 before:z-10'>
                <div className='container mx-auto relative z-30 px-3'>
                    <div className='sm:max-w-[60%]'>
                        <h2 className='font-bold text-2xl sm:text-3xl text-white xl:text-5xl'>Start your trading journey <br /> with ₹ 1000</h2>
                        <h4 className='font-medium text-xl sm:text-[40px] my-7 leading-7 sm:leading-[2.6rem] text-white'>With Trade24 trading has never been easier.</h4>
                        <p className='text-white font-light text-sm sm:text-base'><span className='underline'>Ready to start Trading?</span> Get started in 30 seconds.</p>
                        {/* <button className='mt-10 solidBtn bg-white text-[#242424] hover:bg-[#03880e] hover:text-white'>Getting started</button> */}
                    </div>
                </div>
            </div>
        </Layout>
    )
}