import React from 'react';
import Layout from '../Layout';
import Title from '../Title';

export default function Platform() {
    return (
        <Layout>
            <Title name={'Platform'} />

            <div className='px-3 sm:px-0'>
                <div className='bg-[#e7ffe5] container sm:mx-auto flex items-center rounded-2xl sm:rounded-3xl p-5 sm:p-14 mt-20 flex-wrap'>
                    <div className='w-full sm:w-[45%] sm:pr-5'>
                        <h2 className="heading">Trade With Trust</h2>
                        <h2 className="heading text-[#03880e]">Trade24</h2>

                        <p className="text-sm sm:text-base text-gray-800 my-8">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        <div className='mt-5'>
                            <button className='solidBtn mr-3 text-sm sm:text-base'>Start Trading</button>
                            <button className='solidBtn bg-white text-[#03880e] hover:bg-[#03880e] hover:text-white text-sm sm:text-base'>Open Web Terminal</button>
                        </div>
                    </div>
                    <div className='w-full mt-8 sm:mt-0 sm:w-[55%]'>
                        <img src='images/graph-thumnail.png' alt='Graph' />
                    </div>
                </div>
            </div>

            <div className='pl-5 pr-5 sm:pr-10 pt-20 flex pb-20 sm:pl-0 sm:pb-0 items-center flex-wrap'>
                <div className='w-full sm:w-[45%] relative max-h-[650px] overflow-hidden xl:max-h-[750px] -left-5 sm:-left-0'>
                    <img src="images/trade-with-us.png" alt='Trade-with-us' className='w-full relative -left-10 xl:w-[85%]' />
                </div>
                <div className='w-full sm:w-[40%] sm:mb-5'>
                    <h2 className="heading"><span className='text-[#03880e]'>Trade24</span> with ARK Technologies</h2>
                    <h4 className='text-[#392557] text-[36px] font-semibold mt-3 mb-6'>Easy and Fast Trading</h4>
                    <p className='text-sm sm:text-base font-light'>Trade24 Trading Platform Offers Clients The Ultimate Trading Experience. Trade24 Takes Trading To The Next Level, Providing Exceptional Execution Under Any Market Conditions Due To The Integrated ArkTrader Trading Technology And The Tightest Spreads , ArkTrader Trading Platforms Give Traders The Advantage They Need To Excel/Profit In Forex Markets Anywhere, Anytime. Trade Quickly, Securely And Confidently Via The ArkTrader Web, ArkTrader Mobile App Or ArkTrader Desktop</p>
                </div>
            </div>

            <div className='pb-10 sm:pb-20 container mx-auto px-3 sm:px-0'>
                <h2 className="heading text-center"><span className='text-[#03880e]'>Wanna</span> try?</h2>
                <div className='mt-10 grid grid-cols-1 sm:grid-cols-4 text-center gap-5'>
                    <div className='border rounded-lg px-4 py-7 cursor-pointer transition-all ease-in-out duration-300 hover:-translate-y-3 hover:shadow-lg'>
                        <img src='images/world-wide-web.png' alt='world-wide-web' className='mx-auto' />
                        <p className='text-sm sm:text-base font-light mt-3 mb-2'>Download on</p>
                        <h5 class="text-xl font-bold">Web</h5>
                    </div>
                    <div className='border rounded-lg px-4 py-7 cursor-pointer transition-all ease-in-out duration-300 hover:-translate-y-3 hover:shadow-lg'>
                        <img src='images/cell-phone.png' alt='cell-phone' className='mx-auto' />
                        <p className='text-sm sm:text-base font-light mt-3 mb-2'>Download on</p>
                        <h5 class="text-xl font-bold">Mobile</h5>
                    </div>
                    <div className='border rounded-lg px-4 py-7 cursor-pointer transition-all ease-in-out duration-300 hover:-translate-y-3 hover:shadow-lg'>
                        <img src='images/apple.png' alt='apple' className='mx-auto' />
                        <p className='text-sm sm:text-base font-light mt-3 mb-2'>Download on</p>
                        <h5 class="text-xl font-bold">IOS</h5>
                    </div>
                    <div className='border rounded-lg px-4 py-7 cursor-pointer transition-all ease-in-out duration-300 hover:-translate-y-3 hover:shadow-lg'>
                        <img src='images/computer-monitor.png' alt='computer-monitor' className='mx-auto' />
                        <p className='text-sm sm:text-base font-light mt-3 mb-2'>Download on</p>
                        <h5 class="text-xl font-bold">Desktop</h5>
                    </div>
                </div>
            </div>
        </Layout>
    )
}