import PropTypes from 'prop-types';

import Header from "./Header";
import Footer from "./Footer";

export default function Layout(props) {
    return (
        <>
            <Header />
                {props.children}
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.any
};