import React from 'react';
import Layout from '../Layout';
import Title from '../Title';
import Faqs from '../Faqs';


export default function Faq() {
    return (
        <Layout>
            <Title name={'FAQs'} />
            
            <div className='mt-20 sm:mt-0'><Faqs /></div>
        </Layout>
    )
}